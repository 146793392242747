import axios from "axios";

const api = axios.create({
  baseURL: "https://zlsj9h4l34.execute-api.us-east-1.amazonaws.com/Production",
  headers: {
    "x-api-key": "YzlOkSGikU8Kr7GedizJG3s7KfBueiGu3kYEsHuR",
  },
});

export default api;
