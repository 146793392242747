import { Container, Icon, Logo, MessageContainer, Subtitle, Title } from "./styles";

import check from ".././../assets/images/check.svg";
import error from ".././../assets/images/error.svg";
import sad from ".././../assets/images/sad.svg";
import logo from ".././../assets/images/logo_jobpass_color_horizontal.svg";
import { useEffect } from "react";
import api from "../../services";

export function Confirmation() {
  const from = new URLSearchParams(window.location.search).get("from");
  const reservation_id = new URLSearchParams(window.location.search).get(
    "reservation_id"
  );
  const token = new URLSearchParams(window.location.search).get("token");

  const pic = from === "confirm" ? check : from === "cancel" ? error : sad;
  const text =
    from === "confirm"
      ? "Reserva Confirmada"
      : from === "cancel"
      ? "Reserva Cancelada"
      : "Não encontrado";

  useEffect(() => {
    (async () => {
      try {
        await api.post(
          `/reservation/${from}?reservation_id=${reservation_id}&token=${token}`
        );
      } catch (error) {
        console.log("error");
      }
    })();
  }, [reservation_id, token, from]);

  return (
    <Container>
      <MessageContainer>
        <Icon src={pic} />
        <Title>{text}!</Title>
        <Subtitle>Obrigado!</Subtitle>
        <Logo src={logo} />
      </MessageContainer>
    </Container>
  );
}
