import { Routes, Route } from "react-router-dom";

import { Confirmation } from "../pages/Confirmation";

export function App() {
  return (
    <Routes>
      <Route path="/" element={<Confirmation />}></Route>
    </Routes>
  );
}
