import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-grow: 1;
  min-height: 100vh;
  background-color: #fafafa;
  justify-content: center;
`;

export const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 500px;
  width: 90%;
  max-width: 800px;
  background: #fff;
  margin-top: 15vh;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
`;

export const Icon = styled.img``;

export const Title = styled.h1`
  margin-top: 40px;
`;

export const Subtitle = styled.h2`
  color: gray;
  margin-top: -10px;
`;

export const Logo = styled.img`
  margin-top: 45px;
`;
